/**
 * @file changelog.jsx
 * @description Changelog page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";
import { config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

/**
 * @function ChangelogPage
 * @description Changelog page.
 * @returns {JSX.Element} Changelog page.
 */

function ChangelogPage() {
  config.autoAddCss = false;

  return (
    <>
      <Meta
        title="Changelog"
        description="Get the latest news about Uninstall-Button."
      />
      <Hero
        title="Changelog"
        subtitle="Get the latest news about Uninstall-Button."
      />
      <div className="section">
        <div className="container">
          <div className="timeline is-centered">
            <header className="timeline-header">
              <span className="tag is-medium">Version 1.0.0</span>
            </header>
            <div className="timeline-item is-danger">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <p className="heading">
                  <span className="tag">1.0.1</span>{" "}
                  <time dateTime="2022-01-14T19:28:44+01:00">
                    14th January 2022
                  </time>
                </p>
                <p>
                  <span className="has-text-danger">&bull;</span> The uninstall
                  button is no longer visible if the package cannot be
                  uninstalled. This now matches the behavior of all other
                  buttons in the ACP.*
                  <br />
                  <span className="has-text-danger">&bull;</span> For improved
                  accessibility, <code>{"<button>"}</code> is now used instead
                  of a <code>{"<a>"}</code>-tag.*
                  <br />
                  <span className="has-text-danger">&bull;</span> Removed a
                  tooltip which was left over from the package list when it was
                  taken over.*
                  <br />
                  <span className="has-text-danger">&bull;</span> The WoltLab
                  Suite™ Core has been excluded as of 5.5.0. As of WSC version
                  5.5, this plugin becomes obsolete, see:{" "}
                  <a
                    href="https://github.com/WoltLab/WCF/commit/f64f401974111420a9fd20ef1c87093267b9e473"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://github.com/WoltLab/WCF/commit/f64f401974111420a9fd20ef1c87093267b9e473
                  </a>
                  <br />
                  <br />
                  <span className="has-text-info">&bull;</span> Thanks to{" "}
                  <a
                    href="http://https//www.woltlab.com/user/1336426-alexander-ebert/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Alexander Ebert
                  </a>{" "}
                  in{" "}
                  <a
                    href="https://github.com/WoltLab/WCF/pull/4631#issuecomment-1013074532"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    WCF/pull/4631
                  </a>{" "}
                  for the feedback.
                </p>
              </div>
            </div>
            <header className="timeline-header">
              <span className="tag is-medium is-danger is-icon">
                <FontAwesomeIcon icon={faXmark} />
              </span>

            </header>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangelogPage;
