/**
 * @file index.jsx
 * @description Home page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import MediumHero from "../components/mediumHero";
import { config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Impressions from "../components/impressions";
import Testimonials from "../components/testimonials";

/**
 * @function IndexPage
 * @returns {JSX.Element} Home page.
 */

function IndexPage() {
  config.autoAddCss = false;

  return (
    <>
      <Meta
        title="Home"
        description="This extension adds a button to uninstall a package on its details page."
      />
      <MediumHero
        title="Uninstall-Button"
        subtitle="This extension adds a button to uninstall a package on its details page."
        buttonText="Download"
        buttonLink="/download"
      />
      <div className="section">
        <div className="container">
          <div className="notification is-warning">
            <span className="icon is-notification-icon">
              <FontAwesomeIcon icon={faWarning} />
            </span>
            This plugin is obsolete with the release of WoltLab Suite™ Core
            5.5.0 and can be safely uninstalled.
          </div>
          <Impressions />
          <Testimonials />
        </div>
      </div>
    </>
  );
}

export default IndexPage;
