/**
 * @file faq.jsx
 * @description FAQ page
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React, { useEffect } from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";
import bulmaCollapsible from "@creativebulma/bulma-collapsible";
import { config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

/**
 * @function FaqPage
 * @returns {JSX.Element} The FAQ page
 */

function FaqPage() {
  config.autoAddCss = false;
  useEffect(() => {
    bulmaCollapsible.attach();
  }, []);

  const questions = [
    {
      question: "How can I install “Uninstall-Button”?",
      answer:
        '(1) Download the extension directly from our site.<br/>(2) Open the <abbr title="" data-tooltip="Administration Control Panel">ACP</abbr> and go to Configuration and click on the + next to Packages. On the new page, click Upload Package, select the package you just downloaded from your local computer and click Submit to upload the package and start the installation. <br/>See also: <a href="https://manual.woltlab.com/en/packages/#upload-packages">https://manual.woltlab.com/en/packages/#upload-packages</a> <br/><br/>(3) After the installation is complete, you can search for a package with the search-bar in the top. On the package-detail-page, you will now find an uninstall-button in the top-right corner.',
    },
    {
      question: "Can I install “Uninstall-Button” on the WoltLab Cloud?",
      answer:
        'Yes, you can install “Uninstall-Button” on the WoltLab Cloud. <br/><br/>(1) Just open the <abbr title="" data-tooltip="Administration Control Panel">ACP</abbr> and go to Configuration and click on the + next to Packages. <br/>(2) On the new page, enter “Uninstall-Button” as a search term. You will then see the extension in the search results. <br/>(3) Click on the + to install the package. <br/>See also: <a href="https://manual.woltlab.com/en/packages/#install-packages">https://manual.woltlab.com/en/packages/#install-packages</a><br/><br/>(4) After the installation is complete, you can search for a package with the search-bar in the top. On the package-detail-page, you will now find an uninstall-button in the top-right corner.',
    },
    {
      question: "Do I get updates?",
      answer:
        'No, you will not receive updates for the extension. <br/>The extension is no longer maintained and will not receive any further updates. <br/>This means that the extension will not be updated to the next major version of WoltLab Suite™. <br/><br/>The reason for this is that the extension got implemented directly into WoltLab Suite™. <br/>You can find more information about the implementation in the <a href="https://github.com/WoltLab/WCF/commit/f64f401974111420a9fd20ef1c87093267b9e473">commit</a> of the WoltLab Suite™ repository.',
    },
    {
      question: "When is the next version coming?",
      answer:
        'There will be no next version, except there is a bug or security issue. <br/><br/>The extension is no longer maintained and will not receive any further updates. <br/>This means that the extension will not be updated to the next major version of WoltLab Suite™. <br/><br/>The reason for this is that the extension got implemented directly into WoltLab Suite™. <br/>You can find more information about the implementation in the <a href="https://github.com/WoltLab/WCF/commit/f64f401974111420a9fd20ef1c87093267b9e473">commit</a> of the WoltLab Suite™ repository.',
    },
    {
      question: "How can I get support?",
      answer:
        'You can get support for the extension in our ticket system. <br/><br/>Open a ticket in our ticket system (<a href="https://felix-d1strict.de/support">https://felix-d1strict.de/support</a>). Alternatively, you can also contact us via E-Mail (<a href="mailto:uninstall-button+support@felix-d1strict.de">uninstall-button+support@felix-d1strict.de</a>) or via our Discord server (<a href="https://discord.gg/h6YHJYb9jk">https://discord.gg/h6YHJYb9jk</a>).',
    },
    {
      question: "How can I report a bug?",
      answer:
        'You can report bugs in our ticket system. <br/><br/>Open a ticket in our ticket system (<a href="https://felix-d1strict.de/support">https://felix-d1strict.de/support</a>). Alternatively, you can also contact us via E-Mail you can also contact us via E-Mail (<a href="mailto:uninstall-button+support@felix-d1strict.de">uninstall-button+support@felix-d1strict.de</a>) or via our Discord server (<a href="https://discord.gg/h6YHJYb9jk">https://discord.gg/h6YHJYb9jk</a>).',
    },
    {
      question: "Am I allowed to remove the branding?",
      answer:
        'No, you are not allowed to remove the branding. <br/><br/>The branding is a small link to our site. This is a small thank you for the free extension. If you want to remove the branding, you can also buy an "Unbranded" license. <br/><br/>You can find more information about the "Unbranded" license on our site (<a href="https://felix-d1strict.de">https://felix-d1strict.de</a>).',
    },
  ];

  return (
    <div data-allow-multiple="true">
      <Meta
        title="Frequently Asked Questions"
        description="Here you can find answers to the most frequently asked questions."
      />
      <Hero
        title="Frequently Asked Questions"
        subtitle="Here you can find answers to the most frequently asked questions."
      />
      <div className="section">
        <div className="container">
          <div className="accordion_first">
            {questions.map((question, index) => (
              <article className="message is-primary" key={index}>
                <a
                  href={"#collapsible-message-accordion-" + index}
                  className="has-text-white"
                  data-action="collapse"
                  id={"collapsible-message-accordion-trigger-" + index}
                >
                  <div className="message-header">
                    <p>{question.question}</p>
                    <span className="icon is-small">
                      <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                  </div>
                </a>
                <div
                  id={"collapsible-message-accordion-" + index}
                  className="message-body is-collapsible"
                  data-parent="accordion_first"
                >
                  <div className="message-body-content">
                    <p dangerouslySetInnerHTML={{ __html: question.answer }} />
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqPage;
